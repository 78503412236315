.WhyUsMainContainer {
  margin: 50px 0px;
}

.GridItemWhyUs {
  box-shadow: none !important;
  /* background-color: #FAFAFA; */
}

.RightMobileWhyUs {
  display: none;
}

.leftMainContainer {
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  /* margin-top: 10px; */
}

.textboxWhyUs {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  
}

.iconItem {
  margin: 20px;
  margin-left: 0;
  background: transparent;
  box-shadow: none;
  background-color: #000 !important;
}

.iconBoxContainer .css-1633um2-MuiPaper-root {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.87) !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding: 0px !important;
  text-align: left !important;
  background-color: transparent !important;
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  align-items: unset;
}

.imageBox {
  width: 25%;
}

.textBoxIcon {
  width: 100%;
}

.IconHeading {
  margin-top: 0px !important;
}

.imageBenefits {
  /* width: 60%; */
}

.WhyUsMainContainer .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}

.ImageContainerMain {
  position: relative;
}

.titleBannerRight {
  background: #7d54c5;
  color: white;
  padding: 25px;
  text-align: center;
  height: 40px;
  max-height: 40px;
  width: 40%;
  position: absolute;
  top: 70%;
  right: 0;
  transform: translateY(-70%);
}

.benifitsHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 52px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;
  margin-top: 0 !important;
  width: 101%;
}

.headingCstm {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: #ffffff;
}

.paraCstm {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #dfdfdf;
}

.IconHeading {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 24px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 0;
  text-align: left;

}

.IconPara {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #DEDEDE;
  mix-blend-mode: normal;
  opacity: 0.8;
  text-align: left;

}

.WhyUsMainContainer .css-1633um2-MuiPaper-root {
  box-shadow: none !important;
}

@media (max-width: 900px) {
  .leftMainContainer {
    margin-top: -2px;
  }

  .RightDesktopWhyUs {
    display: none;
  }

  .RightMobileWhyUs {
    display: block;
  }

  .imageBenefitsMobile {
    width: 100%;
    display: block;
  }

  .WhyUsMainContainer .css-1633um2-MuiPaper-root {
    box-shadow: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}