.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 2em 0 1em 0;
}

.sliderTitle {

    font-weight: 700;
    font-size: 18px;
}

.slider {
    width: 95%; 
    height: auto;
    display: flex;
    /* flex-direction: column; */
    transition: transform 0.5s;
    padding: 1.5em 0;
    padding-left: 1em;

}

.slider img {
    width: 20%;
    height: auto;
    flex-shrink: 0;
    transition: transform 0.5s;
    padding-left: 5px;
}

.slider img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.pagination-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: black;
}