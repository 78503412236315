.active-link {
  border-bottom: 2px solid purple;
}

.desktopMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
}
.LogoSiteMain{
    display: block;
    width: 80%;;
}
.LogoSiteMobile{
    display: none;
}
.loginButton{
background: #7D54C5!important;
}

.MainMenuAppBar {
    background: transparent!important;
    position: absolute!important;
    top: 0!important;
    z-index: 2!important;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root{
    box-shadow: none!important;
}
.moufit-btn-link{
    color: white!important;
    text-decoration: none!important;
}


.menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1.5rem;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    /* background-color: white; */
    color: white;
    font-size: 17px;
  }
  
  .menu-items {
    color: #c2c1c1be;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    color: black;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }
  
  /* content */
  
  .content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content h2 {
    margin-bottom: 1rem;
  }
  
  .content a {
    color: #cc3852;
    margin-right: 10px;
  }

@media(max-width:900px){
    .LogoSiteMain{
        display: none;
    }
    .LogoSiteMobile{
        display: block;
        padding: 0.2rem 0.6rem;
        width: 70%;
    }
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
        overflow-y: auto;
        overflow-x: hidden;
        min-width: 100%;
        width: 100%;
        max-width: 100%!important;
        height: auto;
        max-height: 500px;
        position: relative;
        /* top: 0!important; */
        top: 57px!important;
        left: 0!important;
        background-color: antiquewhite;
        padding:20px 0px
    }
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
        border-bottom: 1px solid lightgray !important;
        
    }
   
}
