@font-face {
  font-family: "Noyh-Light";
  src: local("Noyh-Light"),
   url("./Font/Noyh/Noyh-Light.woff") format("woff");
  font-weight: light;
  }
  @font-face {
    font-family: "Noyh-Regular";
    src: local("Noyh-Regular"),
     url("./Font/Noyh/Noyh-Regular.woff") format("woff");
    font-weight: regular;
  }
  @font-face {
    font-family: "Noyh-Bold";
    src: local("Noyh-Bold"),
     url("./Font/Noyh/Noyh-Bold.woff") format("woff");
    font-weight: bold;
  }
  @font-face {
    font-family: "Rubik-Light";
    src: local("Rubik-Light"),
     url("./Font/Rubik/Rubik-Light.ttf") format("ttf");
    font-weight: light;
  }
  @font-face {
    font-family: "Rubik-Medium";
    src: local("Rubik-Medium"),
     url("./Font/Rubik/Rubik-Medium.ttf") format("ttf");
    font-weight: light;
  }
  @font-face {
    font-family: "Rubik-Regular";
    src: local("Rubik-Regular"),
     url("./Font/Rubik/Rubik-Regular.ttf") format("ttf");
    font-weight: light;
  }
  @font-face {
    font-family: "Rubik-Bold";
    src: local("Rubik-Bold"),
     url("./Font/Rubik/Rubik-Bold.ttf") format("ttf");
    font-weight: light;
  }
  @font-face {
    font-family: "JosefinSans-Regular";
    src: local("JosefinSans-Regular"),
     url("./Font/JosefinSans/JosefinSans-Regular.ttf") format("ttf");
  }




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

