.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 2em 0 1em 0;
  }
  
  .sliderTitle {
    font-weight: 700;
    font-size: 18px;
  }

  .sectionMainPara{
    padding-top: 1em;
  }
  
  .slider {
    width: 95%;
    height: auto;
    display: flex;
    transition: transform 0.5s;
    padding: 1.5em 0;
    padding-left: 1em;
  }
  
  .teamItem {
    height: 100%;
    flex: 0 0 auto;
    margin-left: 1em;
  }
  
  .teamBox {
    display: flex;
    flex-direction: column;
  }
  
  .teamTextbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: '10px'
  }
  
  .memberImage {
    width: 100%;
    height: auto;
  }
  
  .nameMember {
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #3d3d3d;
    margin-bottom: 0 !important;
    text-align: left;
  }
  
  .designationMember {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #6b6b6b;
    text-align: left;
    margin-top: 0 !important;
  }
  
  @media (max-width: 900px) {
    .titleSection {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
    }
    .sectionMainPara {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #666666;
      margin-bottom: 50px;
      width: 90%;
    }
    .nameMember {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 0 !important;
      text-align: left;
    }
    .teamItem {
      height: 315px !important;
      max-height: 310px !important;
    }
  }
  