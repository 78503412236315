.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 100px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
  }
  
  .hover-text:hover .tooltip-text {
    visibility: visible;
  }
  
  #top {
    top: -40px;
    left: -50%;
  }
  
  #bottom {
    top: 25px;
    left: -50%;
  }
  
  #left {
    top: -8px;
    right: 120%;
  }
  
  #right {
    top: -8px;
    left: 120%;
  }
  
  .hover-text {
    position: relative;
    display: inline-block;
    margin: 40px;
    font-family: Arial;
    text-align: center;
  }