.main-Container {
    width: 70%;
    margin: 0 auto;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #6F6F6F;
}

.dynamicSectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 110%;
    font-weight: bold;
    color: black
}

.longLatContainer{
    display: flex;
    width: 110%;
}

.commonInput {
    width: 100%;
    margin: 10px 0px;
    padding: 0px 20px;
    height: 35px;
}

.commonSelect {
    width: 108.3%;
}

.commonTextarea {
    height: 100px;
    padding-top: 2px;
}

.Commonlabel {
    text-align: left;
    margin-bottom: 10px;
}

@media(max-width:900px) {
    .css-1cyom22 {
        width: 90% !important;
        margin: 50 auto !important;
        display: block;
        max-width: 90%;
    }

    .main-Container {
        width: 90%;
        margin: 0;
        display: block;
        max-width: 90%;
    }

    .commonSelect {
        width: 113.3%;
    }

    .css-m5vj9m-MuiStepper-root {
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
    }

    .css-1bw0nnu-MuiStep-root {
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 15px;
        margin: -top;
    }

    .css-j5w0w9-MuiStepConnector-root {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin-top: 15px;
    }
}