.MainBtnBanner{
    width: 100%;
}
.BtnBannerMobile{
    display: none;
}

.containerBtnBanner{
    position: relative;
}
.DesktopBannerImg{
    width: 100%;
    filter: brightness(0.6);
}
.textBoxCstmBtnBanner{
    position: absolute;
    top: 50%;
    color: white;
    transform: translateY(-50%);
    left: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.myBtnCstm{
    margin: 20px 0px;
    padding: 20px;
    background: #7D54C5;
    color: white;
    border: none;
    border-radius: 5px;
    width: 171px;
    max-width: 171px;
}
.txtCstmBanner{
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    text-transform: uppercase;
    color: #FAFAFA;
}



@media(max-width:900px){
    .BtnBannerDesktop{
        display: none;
    }
    .BtnBannerMobile{
        display: block;
        position: relative;
    }
    .textboxMobileBtnBanner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MobileBannerImg{
        width: 100%;
        filter: brightness(0.7);
    }
    .txtCstmBanner{
        color: white;
    }
    .textboxMobileBtnBanner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
    .txtCstmBanner{
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 40px;
        text-transform: uppercase;
        color: #FAFAFA;
        width: 80%;
    }
    
}